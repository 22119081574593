<template>
  <!-- 智能柜 -->
  <div>
    <div class="daohang">
      <div class="xuanzebox">
        <div class="benzu" :class="{ zaixian: index == 1 }" v-for="(item, index) in items" :key="index">
          <p v-if="index == 1"></p>
          <h1 v-if="index == 2"></h1>
          <div style="padding-left: 9px;" :class="{ lixian: index == 2 }">{{ item.name }}({{ item.num }})</div>
        </div>
      </div>
    </div>
    <div class="zhutizhanshi">
      <div class="shebname">智能柜01</div>
      <div class="box">
        <div class="aaaa">
          <div class="fffff" :style="[
            { marginRight: index == 0 ? '60px' : index == 1 ? '20px' : index == 2 ? '60px' : index == 3 ? '20px' : index == 4 ? '60px' : index == 5 ? '0px' : index == 6 ? '60px' : index == 7 ? '15px' : '' },
            { width: index == 0 ? '185px' : index == 1 ? '185px' : index == 2 ? '225px' : index == 3 ? '225px' : index == 4 ? '113px' : index == 5 ? '113px' : index == 6 ? '113px' : index == 7 ? '113px' : '' },
          ]" v-for="(item, index) in dahezilist" :key="index">
            <div class="xxxx" v-for="(j, k) in item.childrens" :key="k">
              <div class="duoshubox"  v-if="index<=1">
                <div>{{ j.id }}号</div><div v-if="j.states!=null" class="xianshi" :class="{xianshiyq:j.states == false}" ></div>
              </div>
              <div class="kuanbox" v-if="index>1&&index<4">
                <div>{{ j.id }}号</div>
                <div v-if="j.states!=null" class="xianshi" :class="{xianshiyq:j.states == false}" ></div>
              </div>
              <div class="zhaibox" v-if="index>3">
                <div>{{ j.id }}号</div>
                <div v-if="j.states!=null" class="xianshi" :class="{xianshiyq:j.states == false}" ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dahezilist: [
        {
          childrens: [
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:false
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
          ]
        },
        {
          childrens: [
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:false
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:false
            },
            {
              id:'1',
              states:true
            },
          ]
        },
        {
          childrens: [
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:false
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:true
            }
          ]
        },
        {
          childrens: [
          {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:false
            },
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:false
            }
          ]
        },
        {
          childrens: [
            {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:false
            }
          ]
        },
        {
          childrens: [
          {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:false
            }
          ]
        },
        {
          childrens: [
          {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:false
            }
          ]
        },
        {
          childrens: [
          {
              id:'1',
              states:true
            },
            {
              id:'1',
              states:false
            }
          ]
        },
      ],
      items: [
        {
          name: '本组: 全部',
          num: '40'
        },
        {
          name: '未借出',
          num: '7'
        },
        {
          name: '已借出',
          num: '5'
        }
      ]
    }
  },
}
</script>

<style lang="less" scoped>
.daohang {
  padding: 55px 26px 0px 27px;
  display: flex;
  justify-content: space-between;

  .xuanzebox {
    display: flex;
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #444444;

    .zaixian {
      color: #4961DF;
    }

    .lixian {
      color: #ACACAC;
    }

    .benzu {
      margin-right: 30px;
      display: flex;

      p::before {
        content: "";
        display: inline-block;
        width: 41px;
        height: 21px;
        border-radius: 10px;
        background-color: #4961DF;
        position: relative;
        bottom: 18px;
      }

      h1::before {
        content: "";
        display: inline-block;
        width: 41px;
        height: 21px;
        border-radius: 10px;
        background-color: #ACACAC;
        position: relative;
        bottom: 26px;
      }
    }
  }
}

.zhutizhanshi {
  padding-bottom: 30px;
  margin: 0px 26px 0px 35px;
  background: #FFFFFF;
  .box{
    margin: 23px 0px 0px 53px;
    width: 1581px;
    height: 780px;
    background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    border: 1px solid #FFAC8F;
    .aaaa{
      margin: 12px 15px 0px 15px;
      display: flex;
      .fffff{
        display: flex;
        flex-direction: column; /* 让子元素垂直排列 */
        justify-content: space-between; /* 在子元素之间均分空间 */
        height: 755px;
        margin-right: 10px;
      }
      .indexclas{
        margin-right: 60px;
      }
      .xxxx{
        height: 62px;
        background: #FFF3EE;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 1px solid #FFAC8F;
        flex-grow: 1; 
        .duoshubox{
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #444444;
          text-align: center; 
          justify-content: space-between;
          display: flex;
          margin-top: 9%;
          padding: 0px 32px 0px 32px;
          .xianshi {
            margin-top: 5px;
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background-color: #516cf7;
          }
          .xianshiyq{
            background-color: #ACACAC ;
          }
        }
        .kuanbox{
          text-align: center; 
          margin-top: 29%;
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #444444;
          .xianshi{
            margin-top: 5px;
            margin-left: 45.5%;
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background-color: #516cf7;
          }
          .xianshiyq{
            background-color: #ACACAC ;
          }
        }
        .zhaibox{
          text-align: center;
          margin-top: 161%;
          font-size: 22px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #444444;
          text-align: center; 
          .xianshi {
            margin-top: 5px;
            margin-left: 45.4px;
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background-color: #516cf7;
          }
        }
      }
    }
  }
  .shebname {
    width: 100%;
    text-align: center;
    font-size: 26px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #444444;
    padding-top: 23px;

  }

 
}</style>