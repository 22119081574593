<template>
  <!-- 智能跳绳页面 -->
  <div>
    <div class="daohang">
      <div class="xuanzebox">
        <div class="benzu" :class="{ zaixian: index == 1 }" v-for="(item, index) in onlineData" :key="index">
          <p v-if="index == 1"></p>
          <h1 v-if="index == 2"></h1>
          <div style="padding-left: 9px;" :class="{ lixian: index == 2 }">{{ item.name }}({{item.state}})</div>
        </div>
      </div>
      <div class="rightbox">
        <div class="anniu" :class="{ xuanzhong: xuanz == index }" v-for="(item, index) in group" :key="index"
          @click="changezs(index)">
          第{{ item }}组</div>
      </div>
    </div>
    <div class="zhanshi">
      <div class="zhanshibox" v-for="(item, index) in tiaoshenlist" :key="index">
        <div :class="{ topys: item.is_online == 1 }" class="zstop">
          <span>{{ item.serial_number }}</span>
        </div>
        <div class="tupianqu">
          <img class="imgs" src="../../../assets/erji.png" alt="">
          <img class="imgs1" src="../../../assets/dianc.png" alt="">
          <span class="shuzi" :class="{ dilian: Number(item.device_electricity) < 100 }" :style="[
            {
              color: item.device_electricity <= 100 && item.device_electricity > 40 ? '#4CDA63'
                : item.device_electricity <= 40 && item.device_electricity > 20 ? '#FFC700'
                  : item.device_electricity <= 20 ? '#FF3636' : ''
            }
          ]">{{ item.device_electricity }}</span>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: end;">
      <el-pagination background :page-size="page_size" layout="prev, pager, next" :total="total"
        @current-change="chang_page">
      </el-pagination>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    // group_count:Array
    tiaoshenlist: Array,
    total: Number,
    group:Number,
    onlineData:Array
  },
  created() {
  },
  mounted() {
  
  },
  data() {
    return {
      onlineDatas:'',
      page_size: 50,
      xuanz: 0,
      // tiaoshenlist:[
      //   {
      //     title:'蓝色-01',
      //     dianliang:20,
      //     states:false
      //   },
      //   {
      //     title:'蓝色-01',
      //     dianliang:100,
      //     states:true
      //   },
      //   {
      //     title:'蓝色-01',
      //     dianliang:100,
      //     states:true
      //   },
      //   {
      //     title:'蓝色-01',
      //     dianliang:100,
      //     states:true
      //   },
      //   {
      //     title:'蓝色-01',
      //     dianliang:20,
      //     states:true
      //   },
      //   {
      //     title:'蓝色-01',
      //     dianliang:100,
      //     states:true
      //   },
      //   {
      //     title:'蓝色-01',
      //     dianliang:40,
      //     states:true
      //   },
      //   {
      //     title:'蓝色-01',
      //     dianliang:100,
      //     states:true
      //   },
      //   {
      //     title:'蓝色-01',
      //     dianliang:100,
      //     states:true
      //   },
      //   {
      //     title:'蓝色-01',
      //     dianliang:40,
      //     states:true
      //   },
      //   {
      //     title:'蓝色-01',
      //     dianliang:20,
      //     states:false
      //   }
      // ],

      anniulist: [
        { aniuname: '第一组' },
        { aniuname: '第二组' },
        { aniuname: '第三组' },
      ],
      items: [
        {
          name: '本组: 全部',
          num: '50'
        },
        {
          name: '在线',
          num: '45'
        },
        {
          name: '离线',
          num: '5'
        }
      ]
    }
  },
  methods: {
    chang_page(a) {
      console.log(a);
      this.$emit('chang_page', a)
    },
    changezs(i) {
      this.xuanz = i
      this.$emit('changezubei',i+1)
    }
  },
}
</script>

<style lang="less" scoped>
.daohang {
  padding: 55px 26px 0px 27px;
  display: flex;
  justify-content: space-between;

  .xuanzebox {
    display: flex;
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #444444;

    .zaixian {
      color: #4961DF;
    }

    .lixian {
      color: #ACACAC;
    }

    .benzu {
      margin-right: 30px;
      display: flex;

      p::before {
        content: "";
        display: inline-block;
        width: 41px;
        height: 21px;
        border-radius: 10px;
        background-color: #4961DF;
        position: relative;
        bottom: 18px;
      }

      h1::before {
        content: "";
        display: inline-block;
        width: 41px;
        height: 21px;
        border-radius: 10px;
        background-color: #ACACAC;
        position: relative;
        bottom: 26px;
      }
    }
  }
}

.rightbox {
  // width: 415px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  display: flex;
  justify-content: space-between;

  .anniu {
    cursor: pointer;
    width: 138px;
    height: 48px;
    background: #fdfdfd;
    border-radius: 24px 24px 24px 24px;
    opacity: 1;
    text-align: center;
    line-height: 48px;
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #444444;
  }

  .xuanzhong {
    background: #516CF7;
    color: #fff;
  }
}

.zhanshi {
  padding: 0px 10px 0px 32px;
  display: flex;
  flex-wrap: wrap;

  .zhanshibox {
    margin: 0px 10px 10px 0px;
    width: 160px;
    height: 163px;
    background: #fff;

    .topys {
      background: #4961DF !important;
    }

    .zstop {
      width: 100%;
      height: 43px;
      text-align: center;
      line-height: 43px;
      background: #ACACAC;
      border-radius: 12px 12px 0px 0px;
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
    }

    .tupianqu {
      .shuzi {
        position: relative;
        right: 31.2px;
        bottom: 32px;
      }

      .dilian {
        position: relative;
        right: 27.2px;
        bottom: 32px;
      }

      .imgs {
        margin: 30px 0px 0px 20px;
        width: 60px;
        height: 62px;
      }

      .imgs1 {
        margin: 22px 0px 0px 20px;
        width: 39px;
        height: 78px;
      }
    }
  }
}</style>