<template>
  <div class="container">
    <div class="main">
      <div class="headerbox">
        <div
          class="navbox"
          :class="{ active: active == item.value }"
          v-for="(item, index) in navlist"
          :key="index"
          @click="changenav(item.value)">
          <div>
            {{ item.label }}
            <div
              v-if="active == item.value"
              class="xianshi"
              :class="{ xianshiyq: item.value == 5 }"></div>
          </div>
        </div>
      </div>
      <!-- 智能跳绳 -->
      <div v-if="active == 'JUMP'">
        <smartRopeVue
          :tiaoshenlist="tiaoshenlist"
          :onlineData="items"
          :group="group"
          :total="total"
          @changezubei="changezubei"
          @chang_page="chang_page" />
      </div>
      <!-- 智能柜 -->
      <div v-if="active == 5">
        <smartCounter />
      </div>
    </div>
  </div>
</template>

<script>
import smartRopeVue from "./components/smartRope.vue";
import smartCounter from "@/views/intelligent/components/smartCounter";
export default {
  components: {
    smartRopeVue,
    smartCounter,
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
    this.school_id = this.user.school_id;
    this.xxxx();
    this.qqqq();
    this.onlineCount();
    this.groupCount();
  },
  data() {
    return {
      school_id: "",
      device_type: "JUMP",
      group_no: 1,
      per_page: 50,
      page: 1,
      group: 0,
      active: "JUMP",
      xuanz: 0,
      total: 0,
      onlineData: {},
      navlist: [
        // {
        //   id: 1,
        //   name: '运动手表'
        // },
        // {
        //   id: 2,
        //   name: '智能跳绳'
        // },
        // {
        //   id: 3,
        //   name: '智慧蹦床'
        // },
        // {
        //   id: 4,
        //   name: '蓝牙信标器'
        // },
        // {
        //   id: 5,
        //   name: '智能器材柜'
        // },
      ],
      tiaoshenlist: [
        {
          title: "蓝色-01",
          dianliang: 20,
          states: false,
        },
        {
          title: "蓝色-01",
          dianliang: 100,
          states: true,
        },
        {
          title: "蓝色-01",
          dianliang: 100,
          states: true,
        },
        {
          title: "蓝色-01",
          dianliang: 100,
          states: true,
        },
        {
          title: "蓝色-01",
          dianliang: 20,
          states: true,
        },
        {
          title: "蓝色-01",
          dianliang: 100,
          states: true,
        },
        {
          title: "蓝色-01",
          dianliang: 40,
          states: true,
        },
        {
          title: "蓝色-01",
          dianliang: 100,
          states: true,
        },
        {
          title: "蓝色-01",
          dianliang: 100,
          states: true,
        },
        {
          title: "蓝色-01",
          dianliang: 40,
          states: true,
        },
        {
          title: "蓝色-01",
          dianliang: 20,
          states: false,
        },
      ],
      anniulist: [
        { aniuname: "第一组" },
        { aniuname: "第二组" },
        { aniuname: "第三组" },
      ],
      items: [
        {
          name: "本组: 全部",
          num: "50",
        },
        {
          name: "在线",
          num: "45",
        },
        {
          name: "离线",
          num: "5",
        },
      ],
    };
  },
  methods: {
    //切换组别
    changezubei(q) {
      this.group_no = q;
      this.qqqq();
    },
    //分页
    chang_page(a) {
      this.page = a;
      this.qqqq();
    },
    //获取设备类型接口
    async xxxx() {
      const res = await this.axios.get(
        `/device/typeList?school_id=${this.school_id}`
      );
      if (res.code == 1) {
        console.log("res.data", res.data);
        this.navlist = res.data;
        // for(let i of res.data){
        //   if(this.active == i.value){
        //     this.group = i.group_count
        //     console.log('this.group',this.group);
        //   }
        // }
      }
    },
    //获取设备列表
    async qqqq() {
      const res = await this.axios.get(
        `/device/List?school_id=${this.school_id}&device_type=${this.device_type}&group_no=${this.group_no}&per_page=${this.per_page}&page=${this.page}`
      );
      this.tiaoshenlist = res.data.data;
      this.total = res.data.total;
      console.log("设备列表", res);
    },
    //获取设备在线数据
    async onlineCount() {
      const res = await this.axios.get(
        `/device/OnlineCount?school_id=${this.school_id}&device_type=${this.device_type}`
      );
      this.onlineData = res.data;
      this.$set(this.items[0], "state", this.onlineData.total_count);
      this.$set(this.items[1], "state", this.onlineData.online_count);
      this.$set(this.items[2], "state", this.onlineData.offline_count);
    },
    //获取组数
    async groupCount() {
      const res = await this.axios.get(
        `/device/groupCount?school_id=${this.school_id}&device_type=${this.device_type}`
      );
      this.group = res.data;
    },
    changenav(v) {
      this.active = v;
      this.device_type = v;
      this.qqqq();
      this.onlineCount();
      this.groupCount();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.xianshi {
  margin-left: 20px;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: #516cf7;
}
.xianshiyq {
  margin-left: 70px;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 10px;
  background-color: #516cf7;
}
.headerbox {
  display: flex;
  margin: 0px 0px 0px 478px;

  .navbox {
    cursor: pointer;
    font-size: 28px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #444444;
    margin-right: 42px;
  }

  .active {
    color: #516cf7;
  }
}

.daohang {
  padding: 55px 26px 0px 27px;
  display: flex;
  justify-content: space-between;

  .xuanzebox {
    display: flex;
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #444444;

    .zaixian {
      color: #4961df;
    }

    .lixian {
      color: #acacac;
    }

    .benzu {
      margin-right: 30px;
      display: flex;

      p::before {
        content: "";
        display: inline-block;
        width: 41px;
        height: 21px;
        border-radius: 10px;
        background-color: #4961df;
        position: relative;
        bottom: 18px;
      }

      h1::before {
        content: "";
        display: inline-block;
        width: 41px;
        height: 21px;
        border-radius: 10px;
        background-color: #acacac;
        position: relative;
        bottom: 26px;
      }
    }
  }
}

.rightbox {
  width: 415px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  display: flex;
  justify-content: space-between;

  .anniu {
    cursor: pointer;
    width: 138px;
    height: 48px;
    background: #fdfdfd;
    border-radius: 24px 24px 24px 24px;
    opacity: 1;
    text-align: center;
    line-height: 48px;
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #444444;
  }
  .xuanzhong {
    background: #516cf7;
    color: #fff;
  }
}

.zhanshi {
  padding: 0px 10px 0px 32px;
  display: flex;
  flex-wrap: wrap;
  .zhanshibox {
    margin: 0px 10px 10px 0px;
    width: 160px;
    height: 163px;
    background: #fff;
    .topys {
      background: #4961df !important;
    }
    .zstop {
      width: 100%;
      height: 43px;
      text-align: center;
      line-height: 43px;
      background: #acacac;
      border-radius: 12px 12px 0px 0px;
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    .tupianqu {
      .shuzi {
        position: relative;
        right: 31.2px;
        bottom: 32px;
      }
      .dilian {
        position: relative;
        right: 27.2px;
        bottom: 32px;
      }
      .imgs {
        margin: 30px 0px 0px 20px;
      }
      .imgs1 {
        margin: 22px 0px 0px 20px;
      }
    }
  }
}
</style>
